@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.imageCarousel__container { 
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  margin-bottom: 120px;
  /* border: 1px solid red; */
}

.carouselItem { 
  width: 100%;
  height: 100vh;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out; 
}
.carouselImageActive { 
  opacity: 1;
}

.carouselDots__container {
  width: 90vw;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
}

.carouselDots { 
  width: 20px;
  height: 20px;
  background-color: #b5b5b5;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  margin-top: 410px;
}
.carouselDots:hover { 
  background-color: #787878;
}